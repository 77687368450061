<template>
  <div class="container-fluid mt-3 mb-3">
    <CCard>
      <CRow class="p-4">
        <CCol cols="12" :md="12">
          <div v-if="serverErrors.length">
            <CAlert
                v-model="showDismissibleAlert"
                color="danger"
                closeButton
                v-for="err in serverErrors"
                :key="err"
            >
              {{ err }}
            </CAlert>
          </div>
          <validation-observer ref="formValidator">
            <form v-on:submit.prevent="importBill">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label>File </label><br>
                    <input
                        required
                        ref="fileInput"
                        type="file"
                    />
                  </div>
                </div>
                <div>
                  <button type="submit" class="btn btn-primary">Submit</button>
                </div>
              </div>
              <div class="">
                Download Example file: <a download="" href="/example_bill.xlsx">Example file</a>
              </div>
            </form>
          </validation-observer>
        </CCol>
      </CRow>
    </CCard>
  </div>
</template>

<script>
export default {
  name: "CustomerImport",
  data: () => {
    return {
      breadcrumbs: [
        {text: "Home", path: "/dashboard", isActive: false},
        {text: "Billing", path: "/bills", isActive: false},
        {text: "Import Bill", path: "", isActive: true}
      ],
      meter: {
        project: {},
      },
      serverErrors: [],
      showDismissibleAlert: false,
      isLoading: false,
    };
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
  methods: {
    importBill() {
      this.loadingState = true
      const formData = new FormData();
      formData.append('file', this.$refs.fileInput.files[0]);
      const headers = {'Content-Type': 'multipart/form-data'};
      this.$store
          .dispatch("Bill/import", formData, headers)
          .then(() => {
            this.showToast = true;
            this.$toastr.s(
                "success",
                "Your file uploading. When upload completed, then notify to you.",
                "Meter Importing"
            );
          })
          .catch((err) => {
            let errors = err?.response?.data?.message
            if (Array.isArray(errors)) {
              for (let field of Object.keys(errors)) {
                this.$toastr.e("error", errors[field][0], "Error!");
              }
            } else {
              this.$toastr.e("error", errors, "Error!");
            }
          }).finally(() => {
        this.loadingState = false
        this.$refs.fileInput.value = ''
      });
    },
  }
}
</script>
